import logo from './logo.svg';

export default function PageSearch() {
    return (
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
                Binnenkort beschikbaar!
            </p>
        </header>
    )
}